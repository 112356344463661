import { Fragment, useEffect, useState } from 'react';
import OrderHistory from '../components/OrderHistory';
//import UserCard from '../components/UserCard';


export default function UserProfile() {



    const [order, setOrderhistory] = useState([])
	//const [user, setUser] = useState([])


	useEffect(()=>{
		let token = localStorage.getItem('token');
		fetch('https://protected-cliffs-71892.herokuapp.com/users/cart/getUserOrder' ,{
			method:'GET',
			headers:{
				Authorization:`Bearer ${token}`
			}
		})
		.then(res=>res.json())
		.then(data=>{

			setOrderhistory(data.map(Order => {
					console.log(Order)
					return (
						<OrderHistory key={Order.id} orderhistoryProp={Order} />
					)


				}).reverse())
			})

	}, [])

	// useEffect(()=>{
	// 	let token = localStorage.getItem('token');
	// 	fetch('http://localhost:4000/users/details' ,{
	// 		method:'GET',
	// 		headers:{
	// 			Authorization:`Bearer ${token}`
	// 		}
	// 	})
	// 	.then(res=>res.json())
	// 	.then(data=>{

	// 		setUser(data.map(User => {
	// 				console.log(User)
	// 				return (
	// 					<UserCard key={User.id} userProp={User} />
	// 				)


	// 			}))
	// 		})

	// }, [])


	return (
		<Fragment>
			{/* <h1>User Information</h1>
			{user} */}
			<h1>Order History</h1>
			{order}
		</Fragment>

	)
}