import { Redirect } from 'react-router-dom'
import { Fragment, useEffect, useState, useContext } from 'react';
import { Container } from 'react-bootstrap';
import UserContext from '../UserContext'
import ProductCard from '../components/ProductCard';


export default function Products() {
	const { user, setUser } = useContext(UserContext)
	const [product, setProduct] = useState([])

	useEffect(() => {
		fetch('https://protected-cliffs-71892.herokuapp.com/products/activeProduct')
			.then(res => res.json())
			.then(data => {

				setProduct(data.map(product => {

					return (
						<ProductCard key={product.id} productProp={product} />

					)


				}))
			})

	}, [])



	return (

		//admin no access to product page
		(user.id !== null && user.isAdmin === true) ?
			<Redirect to='/admin' />

			:

			<Fragment>
				<h1>Products</h1>
				<Container className="">
					<div className="d-flex flex-wrap flex-column flex-sm-row">
						{product}
					</div>
				</Container>

			</Fragment>

	)
}