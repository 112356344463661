import { useState, useContext, useEffect } from 'react';
import { Card, Button, Row, Col, Stack } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Products from '../pages/Products';
import Swal from 'sweetalert2'
import UserContext from '../UserContext'


export default function CartCard({ cartProps, cart }) {
	const [amount, setAmount] = useState(0)
	let totalAmount = 0;
	let subtotalArr = []

	const history = useHistory()
	const [quantityUI, setQuantityUI] = useState(0)
	const [subtotalUI, setSubtotalUI] = useState(0)

	const { user, totalAmountUI, setTotalAmountUI } = useContext(UserContext)

	const cartId = cart._id;

	const { name, product, subtotal, price, quantity, _id } = cartProps


	const updateTotalAmount = () => {

		let token = localStorage.getItem('token');
		fetch('https://protected-cliffs-71892.herokuapp.com/users/cart/getCart', {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(res => res.json())
			.then(data => {


				data.cartItems.map(cartItem => {

					subtotalArr.push(cartItem.subtotal)
					console.log(subtotalArr)
					totalAmount = subtotalArr.reduce(add, 0)
					function add(accumulator, a) {
						return accumulator + a;
					}

					setTotalAmountUI(totalAmount)
					console.log(totalAmountUI)

				})
			})
	}

	useEffect(() => {

		setQuantityUI(quantity)
		setSubtotalUI(subtotal)
		let token = localStorage.getItem('token');
		fetch('https://protected-cliffs-71892.herokuapp.com/users/cart/getCart', {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(res => res.json())
			.then(data => {


				data.cartItems.map(cartItem => {

					subtotalArr.push(cartItem.subtotal)
					console.log(subtotalArr)
					totalAmount = subtotalArr.reduce(add, 0)
					function add(accumulator, a) {
						return accumulator + a;
					}

					setTotalAmountUI(totalAmount)

				})
			})

	}, [])
	// add function for edit quantity
	const addQuantity = (prodId, price) => {
		setQuantityUI(quantityUI + 1)
		setSubtotalUI(price * quantityUI)
		updateTotalAmount()
		fetch('https://protected-cliffs-71892.herokuapp.com/users/cart/add', {
			method: 'PUT',
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`
			},
			body: JSON.stringify({
				cartItems:
				{
					product: prodId,
					price: price

				}
			})

		})

	}

	// add function for edit quantity
	const decQuantity = (prodId, price) => {
		setQuantityUI(quantityUI - 1)
		setSubtotalUI(price * quantityUI)
		updateTotalAmount()
		fetch('https://protected-cliffs-71892.herokuapp.com/users/cart/minus', {
			method: 'PUT',
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`
			},
			body: JSON.stringify({
				cartItems:
				{
					product: prodId,
					price: price

				}
			})

		})

	}


	const refreshPage = () => {
		window.location.reload();
	}
	const removeItem = (cartId) => {
		console.log(cartId)
		fetch('https://protected-cliffs-71892.herokuapp.com/users/cart/remove', {
			method: 'PUT',
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`
			},
			body: JSON.stringify({
				cartId: cartId,
				userId: user.id
			})
		})
		Swal.fire({
			title: 'Done!',
			icon: 'success',
			text: 'You have successfully remove the item'
		})
		refreshPage()
	}

	useEffect(() => {
		let token = localStorage.getItem('token');
		fetch('https://protected-cliffs-71892.herokuapp.com/users/cart/getCart', {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(res => res.json())
			.then(data => {


				data.cartItems.map(cartItem => {

					subtotalArr.push(cartItem.subtotal)
					console.log(subtotalArr)
					totalAmount = subtotalArr.reduce(add, 0)
					function add(accumulator, a) {
						return accumulator + a;
					}

					// setAmount(totalAmount)

				})
			})

	}, [])


	return (
		<Card className="h-30 mx-1">
			<Card.Body>
				<Card.Title>{name}</Card.Title>
				<Card.Subtitle>Price</Card.Subtitle>
				<Card.Text>{price}</Card.Text>
				<Card.Subtitle>Quantity</Card.Subtitle>
				<Card.Text value="quantityUI">{quantityUI}</Card.Text>
				{quantityUI == 1 ?
					<Button disabled variant="success" onClick={() => decQuantity(product, price)}>-</Button>
					:
					<Button variant="success" onClick={() => decQuantity(product, price)}>-</Button>
				}

				<Button variant="success" onClick={() => addQuantity(product, price)} className="mx-2">+</Button>
				<Card.Subtitle className="mt-1">subtotal</Card.Subtitle>
				<Card.Text value="subtotalUI">{subtotalUI}</Card.Text>
				<Button variant="danger" onClick={() => removeItem(_id)}>Remove Item</Button>
			</Card.Body>
		</Card>



	)
}