
import { Fragment, useEffect, useState, useContext } from 'react';
import { Link, useHistory, Redirect } from 'react-router-dom';
import CartCard from '../components/CartCard';
import { Button, Container } from 'react-bootstrap';
import UserContext from '../UserContext'
import Swal from 'sweetalert2'

export default function Cart() {

	const [cartItem, setCartItem] = useState([])
	const [amount, setAmount] = useState(0)
	let totalAmount = 0;
	let subtotalArr = []

	const history = useHistory()

	const [cartId, setCartId] = useState("")

	const { user, totalAmountUI, setTotalAmountUI } = useContext(UserContext)

	console.log(cartId)
	// checkout function
	const checkout = () => {
		console.log(cartId)
		console.log(totalAmountUI)
		fetch('https://protected-cliffs-71892.herokuapp.com/users/cart/checkout', {
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`
			},
			body: JSON.stringify({
				cartId: cartId,
				totalAmount: totalAmountUI
			})
		})
		Swal.fire({
			title: 'Checkout successfully',
			icon: 'success',
			text: 'You have successfully checkout your order'
		})

		history.push("/")
		// .then(res => res.json())
		// .then((orderUpdate, error)=> {



		// 	if(orderUpdate){
		// 		Swal.fire({
		// 			title: 'Checkout successfully',
		// 			icon: 'success',
		// 			text: 'You have successfully checkout your order'
		// 		})

		// 		history.push("/")

		// 	}else{
		// 		Swal.fire({
		// 			title: 'Something went wrong',
		// 			icon: 'error',
		// 			text: 'Please try again'
		// 		})

		// 		history.push("/cart")
		// 	}

		// })
	}



	useEffect(() => {

		let token = localStorage.getItem('token');
		fetch('https://protected-cliffs-71892.herokuapp.com/users/cart/getCart', {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(res => res.json())
			.then(data => {

				setCartId(data._id)
				setCartItem(data.cartItems.map(cartItem => {

					subtotalArr.push(cartItem.subtotal)
					console.log(subtotalArr)
					totalAmount = subtotalArr.reduce(add, 0)
					function add(accumulator, a) {
						return accumulator + a;
					}

					setTotalAmountUI(totalAmount)
					setAmount(totalAmount)

					return (
						<CartCard key={cartItem.id} cartProps={cartItem} cart={data} />

					)
				}).reverse())
			})

	}, [])

	return (

		(user.id !== null && user.isAdmin === true) ?
			<Redirect to='/admin' />

			:


			<Fragment>
				{cartItem.length !== 0 ?
					<Fragment>
						<h1>Cart Items</h1>
						<div className="d-flex flex-wrap my-1 flex-column flex-sm-row">
							{cartItem}
						</div>
						<h3>Total amount : {totalAmountUI} </h3>
						<Button variant="danger" onClick={() => checkout()}>Checkout</Button>
					</Fragment>
					:
					<h1>Cart is Empty</h1>
				}
			</Fragment>
	)
}