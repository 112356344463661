import { useState } from 'react';
import { Card, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import React from 'React'
import Order from '../pages/Order';
// import './Order.css';
export default function OrderHistory({ orderhistoryProp }) {
   
  const { totalAmount, purchasedOn, cartItems } = orderhistoryProp
  console.log(cartItems)
  // cartItems.map(item=> {
  //    setData(item)
  // })

  // console.log(data)
  return (

    <Table striped bordered hover  >
      <thead>
        <tr>
          
          <th>Date of Purchase</th>
          <th>Name</th>
          <th>Quantity</th>
          <th>Price</th>
          <th>Subtotal</th>
          <th>Total Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr>

          <td>{purchasedOn}</td>
           <td>
        {cartItems.map(item => <div className="align-me">{item.name}</div>)}
        </td>
        <td>
        {cartItems.map(item => <div className="align-me">{item.quantity}</div>)}
        </td>
        <td>
        {cartItems.map(item => <div className="align-me">{item.price}</div>)}
        </td>
        <td>
        {cartItems.map(item => <div className="align-me">{item.subtotal}</div>)}
        </td>
         <td>{totalAmount}</td>
        </tr>         {/* {cartItems.map(item => <div>{item.name}</div>)}
          {cartItems.map(item => <td>{item.price}</td>)}
          {cartItems.map(item => <td>{item.subtotal}</td>)}
          {cartItems.map(item => <td>{item.quantity}</td>)}*/}

      
        <tr>
           
        </tr>
       


      </tbody>
    </Table>

  )
}