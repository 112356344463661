
import { Row, Col, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom'

export default function Banner({ data }) {

	const { title, content, destination, label } = data

	return (
		<Container>
			<Row className="text-center text-dark banner">
				<hr></hr>
				<Col className="p-5">
					<h1>{title}</h1>
					<p>{content}</p>

					<Link to={destination}>
						<Button variant="success" to={destination}>{label}</Button>
					</Link>
				</Col>
				<hr></hr>
			</Row>
		</Container>



	)

}