
import './App.scss';
import {BrowserRouter as Router} from 'react-router-dom'
import {useState,useEffect} from 'react'
import {Container} from 'react-bootstrap'

import{Route, Switch} from 'react-router-dom'

import AppNavbar from './components/AppNavbar'
import ProductCard from './components/ProductCard'
import NotFound from './components/NotFound'
import {UserProvider} from './UserContext'

import Home from './pages/Home'
import Cart from './pages/Cart'
import Products from './pages/Products'
import Register from './pages/Register'
import Login from './pages/Login'
import Logout from './pages/Logout'
import Admin from './pages/Admin'
import Order from './pages/Order'


function App() {

///Paolo Test
const [user,setUser]= useState({

    id:null,
    isAdmin:null
})

const unsetUser = () =>{
    localStorage.clear()
}


// total amount useState for Cart page
const [totalAmountUI, setTotalAmountUI] = useState(0)

useEffect(()=>{

    let token = localStorage.getItem('token');
    fetch('https://protected-cliffs-71892.herokuapp.com/users/details',{
        method:'GET',
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
    .then(res=>res.json())
    .then(data=>{
        console.log(data)

        if (typeof data._id !== "undefined"){
        setUser({
          id: data._id,
          isAdmin: data.isAdmin
        })
      }else{
        setUser({
          id:null,
          isAdmin: null
        })
      }
    })
},[])


 return(
        <UserProvider value={{user,setUser,unsetUser, totalAmountUI, setTotalAmountUI}}>
            

        
 		<Router>
 			<AppNavbar/>
 			

            <Container>
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/products' component={Products} />
                     <Route exact path='/cart' component={Cart} />
                    <Route exact path="/login" component={Login}/>
                   <Route exact path="/register" component={Register}/>
                   <Route exact path="/logout" component={Logout}/>
                   <Route exact path="/admin" component={Admin}/>
                   <Route exact path="/order" component={Order}/>
                    <Route component={NotFound} />
                    
                </Switch>
                
            </Container>
 		</Router>
        </UserProvider>

 	)
}

export default App;
