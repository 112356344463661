import { Fragment, useContext } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// import Navbar from 'react-bootstrap/Navbar'
// import Nav from 'react-bootstrap/Nav'
import '../App.css';
import UserContext from '../UserContext'



export default function AppNavbar() {

	const { user } = useContext(UserContext);

	return (
		<Navbar bg="dark" variant="dark" text-color="light" expand="lg" className="mx-auto">
			<Navbar.Brand as={Link} to="#">blackPlease... </Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav className="mr-auto">
					<Nav.Link as={Link} to='/'>Home</Nav.Link>


					{(user.id !== null && user.isAdmin == false) ?
						<Fragment>
							<Nav.Link as={Link} to='/products'>Products</Nav.Link>
							<Nav.Link as={Link} to='/cart'>Cart</Nav.Link>
							<Nav.Link as={Link} to='/order'>Order History</Nav.Link>
							<Nav.Link as={Link} to='/logout'>Logout</Nav.Link>
						</Fragment>

						: (user.id !== null && user.isAdmin == true) ?
							<Fragment>
								<Nav.Link as={Link} to='/admin'>Admin Dashboard</Nav.Link>
								<Nav.Link as={Link} to='/logout'>Logout</Nav.Link>
							</Fragment>

							:
							<Fragment>
								<Nav.Link as={Link} to='/products'>Products</Nav.Link>
								<Nav.Link as={Link} to="/register" exact>Register</Nav.Link>
								<Nav.Link as={Link} to="/login" exact>Login</Nav.Link>



							</Fragment>
					}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	)
}





