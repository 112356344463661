import React from 'react'
import {Button} from 'react-bootstrap'





export default function EditRow({editFormData,handleEditFormChange,cancelClick}){
	return(
			<tr>
				{/* <td>
					<input 
					type="text"
					name="id"
					disabled
		     		placeholder="id" 
		     		value={editFormData.id}
		     		
		     		/>
				</td> */}
				<td>
					<input 
					type="text"
					name="name"
					required
		     		placeholder="product" 
		     		value={editFormData.name}
		     		onChange={handleEditFormChange}
		     		/>
				</td>

				<td>
					<input 
					type="text"
					name="description"
					required
		     		placeholder="description" 
		     		value={editFormData.description}
		     		onChange={handleEditFormChange}
		     		/>
				</td>

				<td>
					<input 
					type="text"
					name="category"
					required
		     		placeholder="category" 
		     		value={editFormData.category}
		     		onChange={handleEditFormChange}
		     		/>
				</td>
				
				<td>
					<input 
					type="text"
					name="price"
					required
		     		placeholder="price" 
		     		value={editFormData.price}
		     		onChange={handleEditFormChange}
		     		/>
				</td>

				<td>
					<input 
					type="text"
					name="stock"
					required
		     		placeholder="stock" 
		     		value={editFormData.stock}
		     		onChange={handleEditFormChange}
		     		/>
				</td>

				<td>
					<input 
					type="text"
					name="isActive"
					disabled
		     		placeholder="isActive"
		     		value={editFormData.isActive}
		     		onChange={handleEditFormChange} 
		     		/>
				</td>

				<td>
					<Button type='submit' >Save</Button>
					

				</td>
				<td>
				<Button type='button' onClick={cancelClick}>Cancel</Button>
				</td>






				
 			 	{/*<th>Picture</th>*/}
  				
			</tr>
		
		


		);
};