import {Fragment} from 'react';
import Banner from '../components/Banner';

 



export default function Home(){

	const data={
		title: "blackPlease... Coffee ",
	 	content: "Coffee for everyone, anywhere",
	 	destination:"/products",
	 	label: "Order Now!"
 }

	 return(
	 	<Fragment>
	 		<Banner data={data} />
	 	</Fragment>


	 	)
}