import { useState, useEffect } from 'react'
import { useContext } from 'react'
import { Form, Button } from 'react-bootstrap'
import { Redirect, useHistory } from 'react-router-dom'
import UserContext from '../UserContext'
import Swal from 'sweetalert2'


export default function Register() {

	const history = useHistory()
	const { user, setUser } = useContext(UserContext)

	// const [firstName, setFirstName] = useState('')
	// const [lastName, setLastName] = useState('')

	const [email, setEmail] = useState('')
	const [password1, setPassword1] = useState('')
	const [password2, setPassword2] = useState('')

	//to determine if reg btn is enabled or not
	const [isActive, setIsActive] = useState(false)

	function registerUser(e) {

		e.preventDefault()

		fetch('https://protected-cliffs-71892.herokuapp.com/users/register', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({

				email: email,
				password: password1

			})
		})
			.then(res => res.json())
			.then(data => {
				console.log(data)

				if (data === false) {
					setEmail('')

					Swal.fire({
						title: 'Cannot Register',
						icon: 'error',
						text: 'email already exists'
					})
					history.push("/register")


				} else {
					setEmail('');
					setPassword1('');
					setPassword2('');

					Swal.fire({
						title: 'Registration Successful!',
						icon: 'success',
						text: 'welcome to blackPlease... Coffee'
					})
					history.push("/login")
				}
			})
	}

	useEffect(() => {
		if (email !== '' && password1 !== '' && password2 !== '' && password1 === password2) {
			setIsActive(true)
		} else {
			setIsActive(false)
		}

	}, [email, password1, password2])


	return (

		(user.id !== null) ?
			<Redirect to='/Products' />

			:

			<Form onSubmit={(e) => registerUser(e)} className="mx-auto register-form">
				<Form.Group>
					<Form.Label>Email:</Form.Label>
					<Form.Control
						type="email"
						placeholder="Please enter your email here"
						value={email}
						onChange={e => setEmail(e.target.value)}
						required
					/>
					<Form.Text className="text-muted">
						We will never share your email with anyone else.
					</Form.Text>
				</Form.Group>

				<Form.Group controlId="password1">
					<Form.Label>Password:</Form.Label>
					<Form.Control
						type='password'
						placeholder="Please input your password here"
						value={password1}
						onChange={e => setPassword1(e.target.value)}
						required
					/>
				</Form.Group>

				<Form.Group controlId='password2'>
					<Form.Label>Verify Password:</Form.Label>
					<Form.Control
						type='password'
						placeholder='Please Verify password'
						value={password2}
						onChange={e => setPassword2(e.target.value)}
						required
					/>
				</Form.Group>

				{isActive ?
					<Button variant="success" type='submit' id="submitBtn" clasName="btnRegister">Register</Button>


					:
					<Button variant="danger" type="submit" id="submitBtn" className="btnRegister" disabled>Register</Button>


				}



			</Form>

	)




}