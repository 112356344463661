import React from 'react'
import { Button } from 'react-bootstrap'






export default function ReadOnlyRow({ product, editClick, archiveClick }) {
	return (

		<tr>
			<td>{product.name}</td>
			<td>{product.description}</td>
			{/*<td>{product.picture}</td>*/}
			<td>{product.category}</td>
			<td>{product.price}</td>
			<td>{product.numberOfStock}</td>
			<td>{product.isActive.toString()}</td>
			<td>
				<Button variant="success" type='button' onClick={(event) => editClick(event, product)}>Edit</Button>
			</td>
			<td>
				<Button variant="danger" type='button' onClick={(event) => archiveClick(event, product)}>Archive</Button>
			</td>
		</tr>



	);
};