import {Redirect} from 'react-router-dom'
import {useEffect, useContext} from 'react'
import UserContext from '../UserContext'

export default function Logout(){

	const {unsetUser, setUser} = useContext(UserContext)
	localStorage.clear()

	unsetUser()

	useEffect(()=>{

		setUser({id:null})
	})

	return(
		<Redirect to='/login' />


		)

}