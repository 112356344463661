import {Row, Col,} from 'react-bootstrap'


export default function NotFound(){
	return(

		<Row>
			<Col>
				<h1>404- Page Not Found</h1>
				<p>Go back to the <a href="/">Homepage</a></p>
			</Col>
		</Row>

		)
}