import { useState, useContext } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Products from '../pages/Products';
import UserContext from '../UserContext'
import Swal from 'sweetalert2'



export default function ProductCard({ productProp }) {


	const { user, setUser } = useContext(UserContext)
	console.log(productProp)
	const { name, description, price, quantity, _id } = productProp


	const addToCart = (data) => {

		if (user.id === null) {
			Swal.fire({
				title: 'Please login first',
				icon: 'error',
				text: 'Then try again'
			})
		} else {
			console.log(data._id)
			fetch('https://protected-cliffs-71892.herokuapp.com/users/cart/addToCart', {
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				body: JSON.stringify({
					cartItems:
					{
						"product": data._id,
						"name": data.name,
						"quantity": 1,
						"price": data.price

					}
				})

			})
				.then(res => res.json())
				.then(data => {

				})

			Swal.fire({
				title: 'Item has been added to cart!',
				icon: 'success'
			})
		}
	}

	return (

		<Card className="h-30 mx-1">
			<Card.Body>
				<Card.Title>{name}</Card.Title>
				<Card.Subtitle>Description</Card.Subtitle>
				<Card.Text>{description}</Card.Text>
				<Card.Subtitle>Price</Card.Subtitle>
				<Card.Text>{price}</Card.Text>
				<Button variant="success" onClick={() => addToCart(productProp)}>Add to Cart</Button>

			</Card.Body>
		</Card>

	)
}

