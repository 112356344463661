import { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import UserContext from '../UserContext';
import Swal from 'sweetalert2';


export default function Login() {

	const { user, setUser } = useContext(UserContext)
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const [isActive, setIsActive] = useState(false)

	console.log(email)
	console.log(password)


	function loginUser(e) {

		e.preventDefault()

		fetch('https://protected-cliffs-71892.herokuapp.com/users/login', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				email: email,
				password: password
			})
		})
			.then(res => res.json())
			.then(data => {
				console.log(data)

				if (typeof data.access !== 'undefined') {
					localStorage.setItem('token', data.access)
					retrieveUserDetails(data.access)

					Swal.fire({
						title: 'Login Successful!',
						icon: 'success',
						text: 'welcome to blackPlease Coffee'
					})
				} else {
					Swal.fire({
						title: 'Authentication failed',
						icon: 'error',
						text: 'Check login details'
					})
				}
			})

		setEmail('');
		setPassword('');

	}

	const retrieveUserDetails = (token) => {
		fetch('https://protected-cliffs-71892.herokuapp.com/users/details', {
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(res => res.json())
			.then(data => {
				console.log(data)

				setUser({
					id: data._id,
					isAdmin: data.isAdmin
				})
			})
	}

	useEffect(() => {

		if (email !== '' && password !== '') {
			setIsActive(true)
		} else {
			setIsActive(false)
		}
	}, [email, password])

	return (

		(user.id !== null && user.isAdmin !== true) ?

			<Redirect to='/products' />

			: (user.id !== null && user.isAdmin == true) ?

				<Redirect to='/admin' />

				:

				<Form onSubmit={(e) => loginUser(e)} className="mx-auto login-form">
					<Form.Group>
						<h3>Login</h3>
						<Form.Label>Email:</Form.Label>
						<Form.Control
							type='email'
							placeholder='Type your email here'
							value={email}
							onChange={e => setEmail(e.target.value)}
							required
						/>
						<Form.Text className="text-muted">
							We will never share your email with anyone else.
						</Form.Text>
					</Form.Group>

					<Form.Group controlId='password'>
						<Form.Label>Password:</Form.Label>
						<Form.Control
							type='password'
							placeholder='Please input your password here'
							value={password}
							onChange={e => setPassword(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group>

						{isActive ?

							<Button variant='success' type='submit' id='submitBtn'>Login</Button>

							:
							<Button variant='secondary' type='submit' id='submitBtn' disabled>Login</Button>

						}

					</Form.Group>

				</Form>
	)

}