
import { Fragment, useContext } from 'react'
import { Table } from 'react-bootstrap'

// import data from '../mockData.json'

import { useEffect, useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2'
import { Redirect, useHistory } from 'react-router-dom';

import UserContext from '../UserContext'
import ReadOnlyRow from '../components/ReadOnlyRow'
import EditRow from '../components/EditRow'
export default function AdminTable() {


	const history = useHistory()

	//for page verification for admin user
	const { user, setUser } = useContext(UserContext)


	const [product, setProduct] = useState([])

	const [name, setName] = useState('')
	const [description, setDescription] = useState('')
	const [category, setCategory] = useState('')
	const [price, setPrice] = useState('')
	const [numberOfStock, setNumberOfStock] = useState('')
	// const [productPicture,setProductPicture] = useState('')

	const [isActive, setIsActive] = useState(false)

	const refreshPage = () => {
		window.location.reload();
	}

	//for archive button
	const [archiveProductId, setArchiveProductId] = useState('')

	const [editProductId, setEditProductId] = useState(null)



	const [editFormData, setEditFormData] = useState({
		id: '',
		name: '',
		description: '',
		category: '',
		price: '',
		stock: ''
		// isActive:''
	})

	const handleEditFormChange = (event) => {
		event.preventDefault();

		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;

		const newFormData = { ...editFormData };
		newFormData[fieldName] = fieldValue

		setEditFormData(newFormData)
	}

	useEffect(() => {
		fetch('https://protected-cliffs-71892.herokuapp.com/products/allProducts')
			.then(res => res.json())
			.then(data => {

				setProduct(data)
			})

	}, [])

	console.log(product)

	function addProduct(event) {
		event.preventDefault();

		// let token = localStorage.getItem('token');
		fetch('https://protected-cliffs-71892.herokuapp.com/products/create', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`
			},
			body: JSON.stringify({

				name: name,
				description: description,
				category: category,
				price: price,
				numberOfStock: numberOfStock,
				// productPicture:productPicture
			})

		})
			.then(res => res.json())
			.then(data => {
				console.log(data)

				if (data === false) {
					setName('')
					setDescription('')
					setCategory('')
					setPrice('')
					setNumberOfStock('')
					// setProductPicture('')

					Swal.fire({
						title: 'Cannot add',
						icon: 'error',
						text: 'adding product failed'
					})



				} else {
					setName('')
					setDescription('')
					setCategory('')
					setPrice('')
					setNumberOfStock('')
					// setProductPicture('')

					Swal.fire({
						title: 'Product Added!',
						icon: 'success',
						// text: ''
					})
					return refreshPage()
				}


			})

	}

	useEffect(() => {
		if (name !== '' && description !== '' && category !== '' && price !== '' && numberOfStock !== '') {
			setIsActive(true)
		} else {
			setIsActive(false)
		}
	}, [name, description, category, price, numberOfStock])



	const editClick = (event, product) => {
		event.preventDefault();
		setEditProductId(product._id);

		const formValues = {
			id: product._id,
			name: product.name,
			description: product.description,
			category: product.category,
			price: product.price,
			stock: product.numberOfStock,
			isActive: product.isActive
		}
		setEditFormData(formValues)
	}
	console.log(editFormData.id)


	function editProduct(event) {
		event.preventDefault()

		fetch(`https://protected-cliffs-71892.herokuapp.com/products/${editFormData.id}/update`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`
			},
			body: JSON.stringify({

				name: editFormData.name,
				description: editFormData.description,
				category: editFormData.category,
				price: editFormData.price,
				numberOfStock: editFormData.numberOfStock,
				// productPicture:productPicture
			})

		})
			.then(res => res.json())
			.then(data => {
				console.log(data)

				if (data === true) {
					setEditProductId(null)
					refreshPage()
				}
			})

	}
	//to utilize cancel button
	const cancelClick = () => {
		setEditProductId(null)
	}

	//to archive a product
	function archiveClick(event, product) {
		// setArchiveProductId(product._id);
		console.log(product._id)
		console.log(!product.isActive)

		// console.log(archiveProductId)
		fetch(`https://protected-cliffs-71892.herokuapp.com/products/${product._id}/archive`, {
			method: 'PUT',
			headers: {
				"Content-Type": 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`
			},
			body: JSON.stringify({
				isActive: !product.isActive
			})
		})
			.then(res => res.json())
			.then(data => {
				if (data === true) {
					refreshPage()
				}
			})
	}



	return (
		(user.id !== null && user.isAdmin !== true || user.id == null) ?
			<Redirect to='/products' />

			:

			<Fragment>
				<div className='app-container'>
					<h5>Add a Product</h5>

					<Form onSubmit={(event) => addProduct(event)}>
						<Row>
							{/*<Col>
		      				<Form.Control 
		      				type="text"
							name="id"
							required="required"
		      				placeholder="id" 
		      				/>
		    			</Col>*/}
							<Col>
								<Form.Control
									type="text"
									name="product"
									required
									placeholder="product"
									value={name}
									onChange={event => setName(event.target.value)}
								/>

							</Col>
							<Col>
								<Form.Control
									type="text"
									name="description"
									required
									placeholder="description"
									value={description}
									onChange={event => setDescription(event.target.value)}
								/>
							</Col>
							{/*	<Col>
		     				 <Form.Control
		     				 type="text"
							 name="picture"
							
		     				 placeholder="picture" 
		     				 />
		    			</Col>*/}
							<Col>
								<Form.Control
									type="text"
									name="category"
									required
									placeholder="category"
									value={category}
									onChange={event => setCategory(event.target.value)}
								/>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="price"
									required
									placeholder="price"
									value={price}
									onChange={event => setPrice(event.target.value)}
								/>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="stock"
									required
									placeholder="stock"
									value={numberOfStock}
									onChange={event => setNumberOfStock(event.target.value)}
								/>
							</Col>

							<Col>
								{isActive ?

									<Button variant="success" type="submit" id="submitBtn">Add</Button>
									:
									<Button variant="danger" type="submit" id="submitBtn" disabled>add</Button>
								}
							</Col>
						</Row>
					</Form>


					<Form onSubmit={(event) => editProduct(event)}>


						<Table>
							<thead>
								<tr>
									<th>Product</th>
									<th>Description</th>
									{/*<th>Picture</th>*/}
									<th>Category</th>
									<th>Price</th>
									<th>Stock</th>
									<th>Active</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{product.map((product) =>

									<Fragment>

										{(editProductId === product._id)
											? <EditRow
												editFormData={editFormData}
												handleEditFormChange={handleEditFormChange}
												cancelClick={cancelClick}
											/>

											: <ReadOnlyRow
												product={product}
												editClick={editClick}
												archiveClick={archiveClick}
											/>
										}

										{/*<ReadOnlyRow product={product}/>*/}

									</Fragment>

								).reverse()}

							</tbody>
						</Table>
					</Form>
				</div>
			</Fragment>

	)

}

